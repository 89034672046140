body {
    font-family: "open sans",sans-serif;
}

.clear-float {
    clear:both;
}

.highlight {
    color:#bbbbbb;
    font-size:40px;
}

img.display-left {
    float:left;
    margin-right:10px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    margin-left:10px;
    margin-bottom:10px;
}

.why-store {
    font-size: 20px;
    color: red;
    margin: 0;
    margin-top: 20px;
}